<template>
  <div class="page" v-if="detailInfo">
    <van-nav-bar title="附件信息" left-arrow @click-left="onClickLeft">
      <!-- <template #right>
        <UserIcon />
      </template> -->
    </van-nav-bar>
    <div class="content">
      <div class="form">
        <div>
          <div class="picture-section">
            <div class="title">户籍地资料</div>
            <div class="pictures">
              <Uploader
                accept="image/*,video/*,audio/*"
                v-model="form.files1"
                :readonly="readonly"
              />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">工作地资料</div>
            <div class="pictures">
              <Uploader
                accept="image/*,video/*,audio/*"
                v-model="form.files2"
                :readonly="readonly"
              />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">居住地资料</div>
            <div class="pictures">
              <Uploader
                accept="image/*,video/*,audio/*"
                v-model="form.files3"
                :readonly="readonly"
              />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">家访对象资料</div>
            <div class="pictures">
              <Uploader
                accept="image/*,video/*,audio/*"
                v-model="form.files4"
                :readonly="readonly"
              />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">客户回款凭证</div>
            <div class="pictures">
              <Uploader
                accept="image/*,video/*,audio/*"
                v-model="form.files5"
                :readonly="readonly"
              />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">车辆照片</div>
            <div class="pictures">
              <Uploader
                accept="image/*,video/*,audio/*"
                v-model="form.files6"
                :readonly="readonly"
              />
            </div>
          </div>
          <div class="picture-section">
            <div class="title">通话录音/视频</div>
            <div class="pictures">
              <Uploader
                accept="video/*,audio/*"
                v-model="form.files7"
                :readonly="readonly"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <van-button v-if="!readonly" type="info" block @click="handleSubmit"
        >提交</van-button
      >
    </div>
  </div>
</template>
<script>
import * as _ from "lodash";
import Api from "@/api/caseHandle";
import { Toast } from "vant";

const DEFAULT_FORM = {
  assignNo: "",
  files1: [],
  files2: [],
  files3: [],
  files4: [],
  files5: [],
  files6: [],
  files7: [],
};

const yesNoEnum = [
  {
    displayName: "是",
    value: "Y",
  },
  {
    displayName: "否",
    value: "N",
  },
];
export default {
  data() {
    return {
      homeNo: this.$route.query.homeNo,
      readonly: !!this.$route.query.readonly,
      yesNoEnum,
      detailInfo: null,

      showPicker: false,

      form: {
        ..._.cloneDeep(DEFAULT_FORM),
      },
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const loading = Toast.loading();
        const { detailInfo } = this;
        const params = _.cloneDeep(this.form);
        const fileList = [
          ...params.files1,
          ...params.files2,
          ...params.files3,
          ...params.files4,
          ...params.files5,
          ...params.files6,
          ...params.files7,
        ];
        if (fileList.length == 0) {
          Toast.fail(`请至少上传一个附件`);
          return;
        }
        Object.assign(params, {
          assignNo: detailInfo.assignNo,
        });
        const res = await Api.finishApply2(params);
        loading.close();
        Toast.success(res.message);
        this.$router.go(-2);
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 加载详情
    async loadDetail() {
      const loading = Toast.loading();
      try {
        const res = await Api.findDetailByHomeNo(this.homeNo);
        this.detailInfo = res.data;
        await this.getFinishApply(this.detailInfo.assignNo);
        loading.close();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 家访反馈信息详情
    async getFinishApply(assignNo) {
      try {
        const res = await Api.getFinishApply(assignNo);
        Object.assign(this.form, res.data);
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
  mounted() {
    this.loadDetail();
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";
.page {
  height: 100%;
  background: @pageBg;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;
    .customer {
      margin: 5px 5px;
    }

    .form {
      margin-bottom: 20px;
    }

    .divder-big {
      height: 10px;
      background: #f2f2f2;
    }

    .divder {
      height: 2px;
      background: #f2f2f2;
    }
    .picture-section {
      padding: 5px 10px;
      background: #fff;
      .title {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 8px;
        margin-top: 10px;
      }
    }
  }

  .actions {
    .van-button {
      border-radius: 0px;
    }
  }
}
</style>